<template>
  <div class="px-3">
    <div>
      <div>
        <div class="mb-1 " >
          <h4 class="editorStyleTitle mt-4">Max File Count:</h4>
            <div class="form-group d-flex align-items-center " >
             
              <input
            
                type="range"
                class="form-control-range formControlRange"
                min="1"
                max="5"
                :key="block.totalFiles+'desktopMAxfileCount'"
                v-model="block.totalFiles"
              />
            
              
               <span  class="ml-3">{{block.totalFiles}}</span>
              
            </div>
           
            
          </div>
        <div class="mb-1 " >
          <h4 class="editorStyleTitle mt-4">Max Total File(s) size MB:</h4>
            <div class="form-group d-flex align-items-center " >
             
              <input
            
                type="range"
                class="form-control-range formControlRange"
                
                max="50"
                :key="block.maxFileSize+'desktopMAxfileSize'"
                v-model="block.maxFileSize"
              />
            
              
               <span  class="ml-3">{{block.maxFileSize}} </span>
              
            </div>
           
            
          </div>
           <div class="row mb-2" >
                  <div class="col-12">
                    <div class="d-flex">
                      <div class="mr-3">
                        <span class="editorStyleTitle">Required: </span>
                      </div>
                      <div class="custom-control custom-switch cursor-pointer">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          :id="`CheckBoxRequired-${Date.now()}`"
                          v-model="block.isRequired"
                        />
                        <label
                          class="custom-control-label"
                          :for="`CheckBoxRequired-${Date.now()}`"
                        ></label>
                      </div>
                    </div>
                  </div>
                </div>

        
        <div class="form-group mt-2 editorStyleTitle">
          <label for="Line1">Text Line 1:</label>
       
          <input
        type="text"
        placeholder="File upload text 1"
         v-model="block.fileTextLine1"
       class="form-control-sm w-100  input-border-style"
      />
           
        </div>
        <div class="form-group mt-2 editorStyleTitle">
          <label for="Line2">Text Line 2:</label>
       
          <input
          type="text"
         id="Line2"
        placeholder="File upload text 2"
         v-model="block.fileTextLine2"
      class="form-control-sm w-100  input-border-style"
      />
           
        </div>
        
        
      </div>

      
      <div class="row my-2 text-setting">
        <div class="col-8 d-flex align-items-center pr-1  mb-2">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="mr-1"
          >
            <path
              d="M2.08333 3.33334V5.83334H6.24999V15.8333H8.74999V5.83334H12.9167V3.33334H2.08333ZM17.9167 7.50001H10.4167V10H12.9167V15.8333H15.4167V10H17.9167V7.50001Z"
              fill="#B3AFB6"
            />
          </svg>

          <select
            class="form-select w-100 form-control-sm pl-2 ml-2"
            aria-label="Default select example"
            v-model="block.style.fontFamily"
          > 
         
<option
v-for="font in getFontFamilyList"
:key="font.value"
:value="font.value"
>
{{ font.value }}
</option>

               
                
          </select>
        </div>
        <div class="col-12 d-flex align-items-center ml-3">
          <div class="row">
            <div class="col-6 px-0">
              <div class="d-flex align-items-center">
            <span class="mr-1">
              <svg
                width="19"
                height="13"
                viewBox="0 0 19 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z"
                  fill="#B3AFB6"
                />
                <path
                  d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z"
                  fill="#B3AFB6"
                />
              </svg>
            </span>
            <input
              type="number"
              min="0"
              max="100"
              style="width: 50px"
              v-model="block.style.fontSize"
              class="form-control-sm w-50 px-2 ml-1 input-border-style"
            />
          </div>
            </div>
           <div class="col-6 px-0" >
              <div class="d-flex align-items-center">
            <label for="" class="mb-0 mr-1 text-muted">H</label>
            <input
              type="number"
              v-model="block.style.InputHeight"
              class="form-control-sm w-50 px-2 ml-1 input-border-style"
            />
          </div>
            </div>
         
          </div>         
        </div>
      </div>

      <div class="row my-2 d-flex align-items-center">
        <div class="col-5 d-flex">
          <span
          
            class="font-style-button"
            :class="{'active-svg': block.style.fontWeight === 'bold'}"
            @click="
              block.style.fontWeight =
                block.style.fontWeight == 'normal' ? 'bold' : 'normal'
            "
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 10 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.00001 5.99165C8.80834 5.43331 9.37501 4.51665 9.37501 3.66665C9.37501 1.78331 7.91668 0.333313 6.04168 0.333313H0.833344V12H6.70001C8.44168 12 9.79168 10.5833 9.79168 8.84165C9.79168 7.57498 9.07501 6.49165 8.00001 5.99165ZM3.33334 2.41665H5.83334C6.52501 2.41665 7.08334 2.97498 7.08334 3.66665C7.08334 4.35831 6.52501 4.91665 5.83334 4.91665H3.33334V2.41665ZM6.25001 9.91665H3.33334V7.41665H6.25001C6.94168 7.41665 7.50001 7.97498 7.50001 8.66665C7.50001 9.35831 6.94168 9.91665 6.25001 9.91665Z"
                fill="#B3AFB6"
              />
            </svg>
          </span>
          <span
            class="mx-1 font-style-button"
            :class="{'active-svg': block.style.fontStyle === 'italic'}"
            @click="
              block.style.fontStyle =
                block.style.fontStyle == 'normal' ? 'italic' : 'normal'
            "
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 10 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.33333 0.333313V2.83331H5.175L2.325 9.49998H0V12H6.66667V9.49998H4.825L7.675 2.83331H10V0.333313H3.33333Z"
                fill="#B3AFB6"
              />
            </svg>
          </span>
          <span
            class="mx-1 font-style-button"
            :class="{'active-svg': block.style.textDecoration === 'underline'}"

            @click="
              block.style.textDecoration =
                block.style.textDecoration == 'underline'
                  ? 'unset'
                  : 'underline'
            "
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 12 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.99999 12.1667C8.75832 12.1667 11 9.925 11 7.16667V0.5H8.91666V7.16667C8.91666 8.775 7.60832 10.0833 5.99999 10.0833C4.39166 10.0833 3.08332 8.775 3.08332 7.16667V0.5H0.99999V7.16667C0.99999 9.925 3.24166 12.1667 5.99999 12.1667ZM0.166656 13.8333V15.5H11.8333V13.8333H0.166656Z"
                fill="#B3AFB6"
              />
            </svg>
          </span>
          <span
            :class="{'active-svg': block.style.textDecoration === 'line-through'}"
            class="font-style-button"
            @click="
              block.style.textDecoration =
                block.style.textDecoration == 'line-through'
                  ? 'unset'
                  : 'line-through'
            "
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.33333 15.8333H11.6667V13.3333H8.33333V15.8333ZM4.16667 3.33334V5.83334H8.33333V8.33334H11.6667V5.83334H15.8333V3.33334H4.16667ZM2.5 11.6667H17.5V10H2.5V11.6667Z"
                fill="#B3AFB6"
              />
            </svg>
          </span>
        </div>
        <div class="col-4 d-flex align-items-center justify-content-around">
          <span 
            :class="{'active-svg': block.style.textAlign === 'left'}"
            class="font-style-button"
           type="button" @click="block.style.textAlign = 'left'">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.22223 9.2222H0.333344V10.7037H9.22223V9.2222ZM9.22223 3.29628H0.333344V4.77776H9.22223V3.29628ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
                fill="#B3AFB6"
              />
            </svg>
          </span>
          <span
            :class="{'active-svg': block.style.textAlign === 'center'}"
          class="font-style-button"
           type="button" @click="block.style.textAlign = 'center'">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.29631 9.2222V10.7037H10.7037V9.2222H3.29631ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM3.29631 3.29628V4.77776H10.7037V3.29628H3.29631ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
                fill="#B3AFB6"
              />
            </svg>
          </span>
          <span
            :class="{'active-svg': block.style.textAlign === 'end'}"
            class="font-style-button"
           type="button" @click="block.style.textAlign = 'end'">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0.166656 11.8334H11.8333V10.5371H0.166656V11.8334ZM4.05555 9.24076H11.8333V7.94447H4.05555V9.24076ZM0.166656 6.64817H11.8333V5.35187H0.166656V6.64817ZM4.05555 4.05558H11.8333V2.75928H4.05555V4.05558ZM0.166656 0.166687V1.46298H11.8333V0.166687H0.166656Z" fill="#B3AFB6"/>
              
            </svg>
          </span>
        </div>
        <div class="col-3 mx-0 px-0">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 5.83332H7.08333L4.16667 2.91666L1.25 5.83332H3.33333V14.1667H1.25L4.16667 17.0833L7.08333 14.1667H5V5.83332ZM8.33333 4.16666V5.83332H18.3333V4.16666H8.33333ZM8.33333 15.8333H18.3333V14.1667H8.33333V15.8333ZM8.33333 10.8333H18.3333V9.16666H8.33333V10.8333Z"
              fill="#B3AFB6"
            />
          </svg>
          <input
            type="number"
            v-model="block.style.lineHeight"
            style="width: 50px"
            class="form-control-sm w-50 px-2 ml-2 input-border-style"
          />
        </div>
      </div>
      <div class="form-group d-flex mb-2 align-items-center row">
        <!-- <strong class="pt-2">Background Color: </strong> -->
        <div class="col-5 d-flex align-items-center pr-0 pl-2">
          <input
            type="color"
            v-model="block.style.color"
            style="min-width:30px;width: 30px; height: 30px"
            class="form-control-color form-control border-0"
          />
          <!-- <span> {{ block.style.color }}</span> -->
          <input type="text"  class="ColorTextInput input-border-style" v-model="block.style.color">
        </div>
        <div class="col-3 d-flex align-items-center px-0">
          <svg
            width="18"
            height="18"
            viewBox="0 0 14 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.7167 5.66665L7.00001 0.958313L2.28334 5.66665C0.983344 6.96665 0.333344 8.69998 0.333344 10.3666C0.333344 12.0333 0.983344 13.7916 2.28334 15.0916C3.58334 16.3916 5.29168 17.05 7.00001 17.05C8.70834 17.05 10.4167 16.3916 11.7167 15.0916C13.0167 13.7916 13.6667 12.0333 13.6667 10.3666C13.6667 8.69998 13.0167 6.96665 11.7167 5.66665ZM2.00001 10.6666C2.00834 8.99998 2.51668 7.94165 3.46668 6.99998L7.00001 3.39165L10.5333 7.04165C11.4833 7.97498 11.9917 8.99998 12 10.6666H2.00001Z"
              fill="#B3AFB6"
            />
          </svg>

          <input
            type="number"
            v-model="block.style.textOpacity"
            class="form-control-sm w-50 px-2 ml-1 input-border-style"
          />
        </div>
      
       
      </div>

       <div class="  mb-2  row">
         
     <span class="editorStyleTitle w-100 pl-2">Dropbox Hover : </span>
     <div class="px-2 d-flex align-items-center">
         <input
          
            type="color"
            v-model="block.style.strokeColor"
            style="min-width:30px;width: 30px; height: 30px"
            class="form-control-color form-control border-0"
          />
          <!-- <span> {{ block.style.color }}</span> -->
          <input type="text" class="ColorTextInput" v-model="block.style.strokeColor">
     </div>
         
       
           
        
    </div>

     
      <div class="row m-0 " v-if="isDesktopView">
         <h4 class="editorStyleTitle mt-4">Margin:</h4>
        <div class="row align-items-center">
          <div class=" col-6 d-flex">
          <div class="row align-items-center">
            <div class="col-4">
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.3334 7.16667H7.83337V15.5H6.16671V7.16667H3.66671L7.00004 3.83333L10.3334 7.16667ZM0.333374 2.16667V0.5H13.6667V2.16667H0.333374Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="col-8">
              <input
                type="number"
                
                v-model="block.style.marginTop"
                class="form-control-sm w-75 px-2  input-border-style"
              />
            </div>
          </div>
        </div>
        <div class=" col-6 d-flex">
          <div class="row align-items-center">
            <div class="col-4">
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.3334 8.83333H7.83337V0.5H6.16671V8.83333H3.66671L7.00004 12.1667L10.3334 8.83333ZM0.333374 13.8333V15.5H13.6667V13.8333H0.333374Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="col-8">
              <input
                type="number"
                v-model="block.style.marginBottom"
                class="form-control-sm w-75 px-2  input-border-style"
              />
            </div>
          </div>
        </div>
        </div>
        
        <div class="row mt-3">
          <div class="form-group col-6 d-flex">
          <div class="row align-items-center">
            <div class="col-4">
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.16667 3.66663V6.16663L15.5 6.16663V7.83329L7.16667 7.83329V10.3333L3.83333 6.99996L7.16667 3.66663ZM2.16667 13.6666L0.5 13.6666V0.333294L2.16667 0.333294V13.6666Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="col-8">
              <input
                type="number"
                v-model="block.style.marginLeft"
                class="form-control-sm w-75 px-2  input-border-style"
              />
            </div>
          </div>
        </div>
        <div class="form-group col-6 d-flex">
          <div class="row align-items-center">
            <div class="col-4">
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.83333 3.66663V6.16663L0.5 6.16663L0.5 7.83329L8.83333 7.83329V10.3333L12.1667 6.99996L8.83333 3.66663ZM13.8333 13.6666L15.5 13.6666V0.333294L13.8333 0.333294V13.6666Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="col-8">
              <input
                type="number"
                v-model="block.style.marginRight"
                class="form-control-sm w-75 px-2  input-border-style"
              />
            </div>
          </div>
        </div>
        </div>
        
      </div>

      <div class="mt-2" v-else>
        <h4 class="editorStyleTitle mt-4">Mobile  margin:</h4>
      <div class="row m-0 ">
        <div class="row align-items-center">
          <div class=" col-6 d-flex">
          <div class="row align-items-center">
            <div class="col-4">
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.3334 7.16667H7.83337V15.5H6.16671V7.16667H3.66671L7.00004 3.83333L10.3334 7.16667ZM0.333374 2.16667V0.5H13.6667V2.16667H0.333374Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="col-8">
              <input
                type="number"
                min="0"
                placeholder="Top"
                v-model="block.style.mobileMarginTop"
                class="form-control-sm w-75 px-2  input-border-style"
              />
            </div>
          </div>
        </div>
        <div class=" col-6 d-flex">
          <div class="row align-items-center">
            <div class="col-4">
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.3334 8.83333H7.83337V0.5H6.16671V8.83333H3.66671L7.00004 12.1667L10.3334 8.83333ZM0.333374 13.8333V15.5H13.6667V13.8333H0.333374Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="col-8">
              <input
                type="number"
                 min="0"
                 placeholder="Bottom"
                v-model="block.style.mobileMarginBottom"
                class="form-control-sm w-75 px-2  input-border-style"
              />
            </div>
          </div>
        </div>
        </div>
        
        <div class="row mt-3">
          <div class="form-group col-6 d-flex">
          <div class="row align-items-center">
            <div class="col-4">
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.16667 3.66663V6.16663L15.5 6.16663V7.83329L7.16667 7.83329V10.3333L3.83333 6.99996L7.16667 3.66663ZM2.16667 13.6666L0.5 13.6666V0.333294L2.16667 0.333294V13.6666Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="col-8">
              <input
                type="number"
                v-model="block.style.mobileMarginLeft"
                 min="0"
                 placeholder="Left"
                class="form-control-sm w-75 px-2  input-border-style"
              />
            </div>
          </div>
        </div>
        <div class="form-group col-6 d-flex">
          <div class="row align-items-center">
            <div class="col-4">
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.83333 3.66663V6.16663L0.5 6.16663L0.5 7.83329L8.83333 7.83329V10.3333L12.1667 6.99996L8.83333 3.66663ZM13.8333 13.6666L15.5 13.6666V0.333294L13.8333 0.333294V13.6666Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="col-8">
              <input
                type="number"
                v-model="block.style.mobileMarginRight"
                 min="0"
                 placeholder="Right"
                class="form-control-sm w-75 px-2  input-border-style"
              />
            </div>
          </div>
        </div>
        </div>
        
      </div>
      </div>
    </div>

   
  </div>
</template>
<script>

import fontFamilyList from "../../../staticPages/fontFamilyList";
export default {
  components: {
  },
  props: {
    block: Object,
    index: Number,
     isDesktopView:Boolean,
  },
  data() {
    return {
     
    };

    //   this.contentLocal = this.content;
  },
  computed:{
    getFontFamilyList() {
return fontFamilyList;
},
   
  }
};
</script>
<style scoped>
.editorStyleTitle input
{
      border-color: #B3AFB6;
      font-size: 14px;
}
.editorStyleTitle
{
  font-weight: 400!important;
}
.text-setting select
{
  font-size: 13px;
    padding: 0;
}
.ql-container.ql-snow {
  background: white;
}


input[type="number"]::-webkit-outer-spin-button ,input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

.PropertyDropdown :deep(.dropdown-toggle){
  background:none;
  color:#000
}
.PropertyDropdown :deep(.dropdown-item:active){
     color: #212529;
    background-color: transparent;
}
.custom-control-input:checked ~ .custom-control-label::before {
            background-color: #ffc107 !important; 
            border-color: #ffc107 !important; 
        }
        .custom-control-input:checked ~ .custom-control-label::after {
            background-color: #fff !important; 
        }
</style>
